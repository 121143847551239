import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Login from '@/views/Login.vue'
import Auth from "@/views/auth/Auth.vue";
import Planning from "@/views/Planning.vue";
import OrderDetail from "@/views/order/OrderDetail.vue";
import OrderAgreement from "@/views/order/OrderAgreement.vue";
import ProductSerialNumber from "@/views/order/ProductSerialNumber.vue";
import Checkout from "@/views/checkout/Checkout.vue";
import OnlineBanking from "@/views/checkout/OnlineBanking.vue";
import PinDevice from "@/views/checkout/PinDevice.vue";
import PaymentSuccess from "@/views/checkout/PaymentSuccess.vue";
import PayCash from "@/views/checkout/PayCash.vue";
import { store } from "@/store";
import PayLater from "@/views/checkout/PayLater.vue";
import PayLaterSuccess from "@/views/checkout/PayLaterSuccess.vue";


const routes: Array<RouteRecordRaw> = [
    {
        path     : '/',
        name     : 'Planning',
        meta     : { requiresAuth: true },
        component: Planning
    },
    {
        path     : '/login',
        name     : 'Login',
        component: Login
    },
    {
        path     : '/auth',
        name     : 'Auth',
        component: Auth
    },
    {
        path     : '/checkout',
        name     : 'Checkout',
        meta     : { requiresAuth: true },
        component: Checkout
    },
    {
        path     : '/checkout/online-banking',
        name     : 'OnlineBanking',
        meta     : { requiresAuth: true },
        component: OnlineBanking
    },
    {
        path     : '/checkout/pin-device',
        name     : 'PinDevice',
        meta     : { requiresAuth: true },
        component: PinDevice
    },
    {
        path     : '/checkout/payment/success',
        name     : 'PaymentSuccess',
        meta     : { requiresAuth: true },
        component: PaymentSuccess
    },
    {
        path     : '/checkout/pay-cash',
        name     : 'PayCash',
        meta     : { requiresAuth: true },
        component: PayCash
    },
    {
        path     : '/checkout/pay-later',
        name     : 'PayLater',
        meta     : { requiresAuth: true },
        component: PayLater
    },
    {
        path     : '/checkout/pay-later/success',
        name     : 'PayLaterSuccess',
        meta     : { requiresAuth: true },
        component: PayLaterSuccess
    },
    {
        path     : '/order/:id',
        name     : 'OrderDetail',
        meta     : { requiresAuth: true },
        component: OrderDetail,
        props    : (route) => ({
            id: route.params.id,
            previousOrderId: route.query.previousOrderId ? parseInt(route.query.previousOrderId as string) : null
        })
    },
    {
        path     : '/order/:id/agreement',
        name     : 'OrderAgreement',
        meta     : { requiresAuth: true },
        component: OrderAgreement
    },
    {
        path     : '/order/:id/serial-numbers',
        name     : 'ProductSerialNumber',
        meta     : { requiresAuth: true },
        component: ProductSerialNumber
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['auth/getAzureAccessToken']) {
            next();
        } else {
            next({
                name: 'Login'
            })
        }
    } else {
        next();
    }
})

export default router
